import React, { Fragment, useState, useEffect} from 'react';
import './style.scss';
import Modal from '../../components/UI/Modal/Modal';
import useToggle from '../../hooks/useToggle';
import MNBA_logo from './i/mnba-logo.png';
import YWCA_logo_white from './i/YWCA_logo_white.svg';
import YWCA_logo_black from './i/YWCA_logo_black_rev.svg';
import hero_logos from './i/hero_logos.gif';
import MNBA_Badge from './i/pdf/MNBA_Web.pdf';
import MNHL_Badge from './i/pdf/MNHL_Web.pdf';
import MPGA_Badge from './i/pdf/MPGA_Web.pdf';
import MMLS_Badge from './i/pdf/MMLS_Web.pdf';
import MNHL_logo from './i/mnhl-logo.png';
import MMLS_logo from './i/mmls-logo.png';
import MPGA_logo from './i/mpga-logo.png';
import Phone_Stickers from './i/phone-stickers.png';
// import videoSrcDesk from '../../assets/video/bb_video_desktop.mp4';
// import videoSrcMobile from '../../assets/video/bb_video_mobile.mp4';
import img_placeholder from './i/img-placeholder.png';
import ywca_edmonton from './i/logo_edmonton.png';
import ywca_cambridge from './i/logo_cambridge.png';
import ywca_halifax from './i/logo_halifax.png';
import ywca_hamilton from './i/logo_hamilton.png';
import ywca_calgary from './i/logo_calgary.png';
import imgMNBA from './i/addthem-mnba.jpg';
import imgMNHL from './i/addthem-mnhl.jpg';
import imgMMLS from './i/addthem-mmls.jpg';
import imgMPGA from './i/addthem-mpga.jpg';
import YouTube from 'react-youtube';
import ReactGA from 'react-ga';



const Home = (props) => {
    const {lang, isMobile} = props;
    //const [showModal, setShowModal] = useState(false);
    const [isToggled, toggle] = useToggle(false);
  //  const [videoSrc, setVideoSrc] = useState(videoSrcMobile);
    const [ytOptions, setYtOptions] = useState({
        height: '260',
        width: '100%',
        playerVars: {
            autoplay: 1
        }
    });
    console.log('home props ', props);

    useEffect(() => {
        if(props.isMobile){
           // setVideoSrc(videoSrcMobile)
        }else {
          //  setVideoSrc(videoSrcDesk)
            setYtOptions({
                height: '100%',
                width: '100%',
                playerVars: {
                    autoplay: 1
                }
            })
        }
    },[props.isMobile]);

    // function for event tracking
    const eventTrack = (category, action, label) => {
        console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
          category: category,
          action: action,
          label: label,
        })
    }

    return (
        <Fragment>
            <div className="blockwrap intro">

                <div className="ywca-bg">
                    <img className="ywca-logo" src={YWCA_logo_black} alt="ywca logo" />
                </div>
               


                <div className="container">

                    <div className="NBA Logo">
                        <img src={hero_logos} alt="MNBA Logo" />
                    </div>

                    <div className="copy">
                        <h1>It’s time to add the M.</h1>
                        <p>Let’s stand up for equity in sport.</p>
                    </div>

                </div>
                    
            </div>

            <div className="blockwrap video">

                <div className="container">
                    <div className="video-wrap">
                        {/* <video id="video" className="" muted loop playsInline>
                        <source src={videoSrc} type="video/mp4" />
                            <p>Your browser doesn't support HTML5 video. Here is a <a href="myVideo.mp4">link to the video</a> instead.</p>
                        </video> */}
                        <YouTube videoId="r5LGZjWAYf4" opts={ytOptions}  />
                    </div>
                </div>
                
            </div>

            <div className="blockwrap badges">
                <h2>Download your badge.</h2>
                <p>And help us level the playing field.</p>

                <div className="downloads">
                    <span>
                        <a className="badge" download="MNBA_Badge.42ac238d.pdf" href={MNBA_Badge} title="Download the MNBA Badge" onClick={eventTrack.bind(this, "Download", "NBA badge", "Button")}>
                            {/* <h4>Car Share Badge</h4> */}
                            <img className="badge mnba" src={MNBA_logo} alt="MNBA logo"  />
                        </a>    
                        <a className="arrow-down" download="MNBA_Badge.42ac238d.pdf" href={MNBA_Badge} title="Download the MNBA Badge" onClick={eventTrack.bind(this, "Download", "NBA badge", "Button")}></a> 
                    </span>
                   
                    <span>
                        <a className="badge mnhl" download="MNHL_Badge.42ac238d.pdf" href={MNHL_Badge} title="Download the MNHL Badge" onClick={eventTrack.bind(this, "Download", "NHL badge", "Button")}>
                            {/* <h4>Car Share Badge</h4> */}
                            <img className="badge mnhl" src={MNHL_logo} alt="MNHL logo"  />
                        </a>    
                        <a className="arrow-down" download="MNHL_Badge.42ac238d.pdf" href={MNHL_Badge} title="Download the MNHL Badge" onClick={eventTrack.bind(this, "Download", "NHL badge", "Button")}></a> 
                    </span>
                    
                    <span>
                        <a className="badge mmls" download="MMLS_Badge.42ac238d.pdf" href={MMLS_Badge} title="Download the MMLS Badge" onClick={eventTrack.bind(this, "Download", "MLS badge", "Button")}>
                            {/* <h4>Car Share Badge</h4> */}
                            <img className="badge mmls" src={MMLS_logo} alt="MMLS logo"  />
                        </a>  
                        <a className="arrow-down" download="MMLS_Badge.42ac238d.pdf" href={MMLS_Badge} title="Download the MMLS Badge" onClick={eventTrack.bind(this, "Download", "MLS badge", "Button")}></a> 
                    </span>

                    <span>
                        <a className="badge mpga" download="MPGA_Badge.42ac238d.pdf" href={MPGA_Badge} title="Download the MPGA Badge" onClick={eventTrack.bind(this, "Download", "PGA badge", "Button")}>
                            {/* <h4>Car Share Badge</h4> */}
                            <img className="badge mpga" src={MPGA_logo} alt="MPGA logo"  />
                        </a>  
                        <a className="arrow-down" download="MPGA_Badge.42ac238d.pdf" href={MPGA_Badge} title="Download the MPGA Badge" onClick={eventTrack.bind(this, "Download", "PGA badge", "Button")}></a> 
                    </span>
                   
                </div>

            </div>

            <div className="blockwrap image-gallery">

                <div className="container">
                    <img src={imgMNBA} alt="MNBA sticker" />
                    <img src={imgMNHL} alt="MNHL sticker" />
                  
                    <img src={imgMMLS} alt="MMLS sticker" />
                    <img src={imgMPGA} alt="MPGA sticker" />
                </div>

            </div>

            <div className="blockwrap stickers">
                <div className="container">

                    <div className="copy">
                        <h2>Add the M Stickers</h2>
                        <p>Search for ‘Add the M’ on giphy to add the M<br className="desktop"></br> to the logos you think should have them. </p>
                        <p className="tag-us"><div className="circle"><i className="lab la-instagram" ></i></div>Tag us&nbsp;<span>@YWCACanada</span></p>
                    </div>
                
                    <img src={Phone_Stickers} alt="add the stickers" />

                </div>
            </div>

            <div className="blockwrap footer">

                <div className="container">

                    <div className="copy">
                        <p>Find out how the YWCA <br className="mobile"></br>supports women and girls in your community.</p>
                        <a className="learn-more" href="https://ywcavan.org/" target="_blank">Learn More</a>
                        <div className="keyline"></div>
                    </div>
                
                    <div className="social-wrap">
                            <img src={YWCA_logo_white}  alt="YWCA Metro Vancouver" />
                            <p className="social">
                                <a className="link-out" href="https://www.instagram.com/ywcavan/?hl=en"  target="_blank"><i className="lab la-instagram" ></i></a> 
                                <a className="link-out" href="https://twitter.com/YWCAVAN" target="_blank" ><i className="lab la-twitter"></i></a>
                                <a className="link-out" href="https://www.facebook.com/YWCAMetroVancouver" target="_blank"><i className="lab la-facebook-f"  ></i></a>
                            </p>
                    </div>
                
                </div>

            </div>

            <div className="blockwrap footer-logos">

                <div className="container">
                    <div className="more-ywca">
                        <a href="https://ywcaofedmonton.org" title="YWCA Of Edmonton" target="_blank">
                            <img src={ywca_edmonton} alt="ywca of edmonton" />
                        </a>
                        <a href="https://ywcacambridge.ca" title="YWCA Of Cambridge" target="_blank">
                            <img src={ywca_cambridge} alt="ywca of Cambridge" />
                        </a>
                        <a href="https://ywcahalifax.com" title="YWCA Of Halifax" target="_blank">
                            <img src={ywca_halifax} alt="ywca of Halifax" />
                        </a>
                        <a href="https://ywcahamilton.org" title="YWCA Of Hamilton" target="_blank">
                            <img src={ywca_hamilton} alt="ywca of Hamilton" />
                        </a>
                        <a href="https://ywcalgary.ca" title="YWCA Of Calgary" target="_blank">
                            <img src={ywca_calgary} alt="ywca of Calgary" />
                        </a>
                    </div>
                </div>
                
            </div>


        </Fragment>
    )
}
export default Home;

{/* <a className="link-out" href="https://twitter.com/trussbeverages" target="_blank" ><i className="lab la-twitter"></i></a>
<a className="link-out" href="https://facebook.com/trussbeverages" target="_blank"><i className="lab la-facebook-f"  ></i></a>
<a className="link-out" href="https://www.instagram.com/trussbeverages/?hl=en"  target="_blank"><i className="lab la-instagram" ></i></a> */}